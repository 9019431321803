//Expects markup on the form

//.js-text-html-parent
//  .js-text-html-content

import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  toggleTextHtmlContent(e) {
    e.preventDefault()
    e.stopPropagation()

    const toggle = e.currentTarget
    const content = toggle.closest(".js-text-html-parent").querySelector(".js-text-html-content")

    if (content.dataset.display === "html") {
      content.dataset.display = "text"
      content.innerHTML = content.dataset.text
      toggle.querySelector(".js-text-html-html").classList.add("hidden")
      toggle.querySelector(".js-text-html-text").classList.remove("hidden")

    } else {
      content.dataset.display = "html"
      content.innerHTML = content.dataset.html
      toggle.querySelector(".js-text-html-text").classList.add("hidden")
      toggle.querySelector(".js-text-html-html").classList.remove("hidden")
    }
  }
}

import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["counter"]
  static values = { count: Number }

  handleDelete(event) {
    this.countValue -= 1
    if (this.countValue > 0) {
      this.counterTarget.textContent = "(" + this.countValue + ")"
      event.target.closest("tr").remove()
    } else {
      this.element.remove()
    }
  }
}

import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["documentCheckbox", "downloadAllButton", "downloadSelectedButton", "submitButton"]

  isDocumentCheckboxChecked() {
    return this.documentCheckboxTargets.some(documentCheckboxTarget => documentCheckboxTarget.checked)
  }

  attachmentSelectorChanged() {
    if (this.isDocumentCheckboxChecked()) {
      this.downloadAllButtonTarget.classList.add("hidden")
      this.downloadSelectedButtonTarget.classList.remove("hidden")
    } else {
      this.downloadAllButtonTarget.classList.remove("hidden")
      this.downloadSelectedButtonTarget.classList.add("hidden")
    }
  }

  submit() {
    this.submitButtonTarget.click()
  }
}

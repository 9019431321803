import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["downIcon", "upIcon"]
  static values = {collapsed: Boolean}
  
  toggleIcon() {
    if (this.collapsedValue) {
      this.downIconTarget.classList.add('hidden')
      this.upIconTarget.classList.remove('hidden')
    } else {
      this.downIconTarget.classList.remove('hidden')
      this.upIconTarget.classList.add('hidden')
    }

    this.collapsedValue = !this.collapsedValue
  }
}
